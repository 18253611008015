import React from 'react';

import carre from "../img/carre.png";
import  barriga from "../img/panceta.png";
import bacon from "../img/bacon.png";
import costela from "../img/costela.png";
import picanha from "../img/picanhasuina.png";
import rabo from "../img/rabo.png";
import orelha from "../img/orelha.png";
import pe from "../img/pesuino.png";

import "./estilobovino.css"; // Importando o arquivo CSS

function Suino() {
    // Exemplo de dados do produto
    
    const produtos = [
      { id: 1, nome: 'Carré ', preco: 'R$ 18,90', imagemUrl: carre  },
      { id: 2, nome: 'Bacon ', preco: 'R$ 15,90', imagemUrl: bacon  },
      { id: 3, nome: 'Costela', preco: 'R$ 19,90', imagemUrl: costela  },
      { id: 4, nome: 'Barriga (Panceta)', preco: 'R$ 15,90', imagemUrl: barriga },
      { id: 5, nome: 'Picanha', preco: 'R$ 24,90', imagemUrl: picanha  },
      { id: 6, nome: 'Rabo Suino', preco: 'R$ 15,90', imagemUrl: rabo  },
      { id: 7, nome: 'Orelha Suina', preco: 'R$ 15,90', imagemUrl: orelha  },
      { id: 8, nome: 'Pé', preco: 'R$ 15,90', imagemUrl: pe  },
      
    ];
   
    const handleComprar = (produto) => {
        const telefone = '5583994447532'; // Substitua pelo número de telefone desejado
        const mensagem = `Olá, tenho interesse em comprar ${produto.nome}.`;
        const url = `https://wa.me/${telefone}?text=${encodeURIComponent(mensagem)}`;
        window.open(url, '_blank');
      };
  
    return (
      <div className="bovino-container">
          <div className='container-titulo'>
              <h1 className="destaque-titulo">DESTAQUES</h1>
              <div className='linha'> 
                  <hr className='linha-transparente' />
              </div>
          </div>
          <div className='container-card-pai'>
              {produtos.map(produto => (
                  <div key={produto.id} className="produto-card">
                      <img src={produto.imagemUrl} alt={produto.nome} />
                      <h3>{produto.nome} KG</h3>
                      <p> {produto.preco} </p>
                      <button onClick={() => handleComprar(produto)}>Comprar</button>
                  </div>
              ))}
          </div>
      </div>
    );
  }
  
  export default Suino;