import React from 'react';
import acemsemosso from "../img/Acem-sem-Osso.png";
import acemcomosso from "../img/acem-com-osso.png";
import paletasemosso from "../img/paleta-sem-osso.png";
import carnemoida from "../img/carne-moida.png";
import guisado from "../img/guisado.png";
import coxaomole from "../img/coxao-mole.png";
import picanha from "../img/picanha.png";
import carnedesol from "../img/carnedesol.png";
import patinho from "../img/patinho.png";
import alcatra from "../img/alcatra.png";
import paletacomosso from "../img/paletacomosso.png";
import costelabovina from "../img/costelabovina.png";
import peitobovino from "../img/peitobovino.png";
import cupimbovino from "../img/cupimbovino.png";
import iscadesol from "../img/iscadesol.png";
import lagarto from "../img/lagarto.png";
import mocoto from "../img/mocoto.png";
import suanbovino from "../img/suanbovino.png";
import Bananinha from "../img/bananinha.png";
import capadefile from "../img/capadefile.png";
import filemignon from "../img/filemigon.png";
import musculo from "../img/musculo.png";
import guisadinhomoido from "../img/guisadinhomoido.png";
import charquepa from "../img/charquepa.png";
import Chambaril from "../img/Chambarril.png";
import fraldinha from "../img/fraldinha.png";
import figado from "../img/figado.png";
import pontadepicanha from "../img/pontadepicanha.png";
import lingua from "../img/lingua.png";
import coracao from "../img/coracao.png";
import tripa from "../img/tripabovina.png";
import bucho from "../img/bucho.png";
import linguicamista from "../img/linguicamista.jpg";
import linguicadefrango from "../img/linguicadefrango.jpg";

import "./estilobovino.css"; // Importando o arquivo CSS



function Bovino() {
  // Exemplo de dados do produto
  const produtos = [
    { id: 1, nome: 'Acém Sem Osso', preco: 'R$ 24,90', imagemUrl: acemsemosso },
    { id: 2, nome: 'Acém Com Osso', preco: 'R$ 21,90', imagemUrl: acemcomosso },
    { id: 3, nome: 'Carne Moída', preco: 'R$ 17,90', imagemUrl: carnemoida },
    { id: 4, nome: 'Paleta Sem Osso', preco: 'R$ 29,90', imagemUrl: paletasemosso },
    { id: 5, nome: 'Paleta Com Osso', preco: 'R$ 24,90', imagemUrl: paletacomosso },
    { id: 6, nome: 'Guisado', preco: 'R$ 24,90', imagemUrl: guisado },
    { id: 7, nome: 'Coxão Mole', preco: 'R$ 36,90', imagemUrl: coxaomole },
    { id: 8, nome: 'Picanha', preco: 'R$ 59,90', imagemUrl: picanha },
    { id: 9, nome: 'Carne de Sol', preco: 'R$ 36,90', imagemUrl: carnedesol },
    { id: 10, nome: 'Patinho', preco: 'R$ 36,90', imagemUrl: patinho },
    { id: 11, nome: 'Miolo Da Alcatra', preco: 'R$ 36,90', imagemUrl: alcatra },
    { id: 12, nome: 'Costela Bovina', preco: 'R$ 21,90', imagemUrl: costelabovina },
    { id: 13, nome: 'Peito Bovino', preco: 'R$ 21,90', imagemUrl: peitobovino },
    { id: 14, nome: 'Cupim A', preco: 'R$ 39,90', imagemUrl: cupimbovino },
    { id: 15, nome: 'Isca de Sol', preco: 'R$ 33,90', imagemUrl: iscadesol },
    { id: 16, nome: 'Lagarto (lombo)', preco: 'R$ 36,90', imagemUrl: lagarto },
    { id: 17, nome: 'Mocotó', preco: 'R$ 10,90', imagemUrl: mocoto },
    { id: 18, nome: 'Suan Bovino', preco: 'R$ 15,90', imagemUrl: suanbovino },
    { id: 19, nome: 'Bananinha', preco: 'R$ 36,90', imagemUrl: Bananinha },
    { id: 20, nome: 'Capa de Filé', preco: 'R$ 31,90', imagemUrl: capadefile },
    { id: 21, nome: 'Filé Mignon', preco: 'R$ 55,00', imagemUrl: filemignon },
    { id: 22, nome: 'Músculo Bovino', preco: 'R$ 21,90', imagemUrl: musculo },
    { id: 23, nome: 'Guisadinho Moído', preco: 'R$ 24,90', imagemUrl: guisadinhomoido },
    { id: 24, nome: 'Charque PA', preco: 'R$ 29,90', imagemUrl: charquepa },
    { id: 25, nome: 'Chambaril Bovino', preco: 'R$ 21,90', imagemUrl: Chambaril },
    { id: 26, nome: 'Fraldinha', preco: 'R$ 36,90', imagemUrl: fraldinha },
    { id: 27, nome: 'Fígado', preco: 'R$ 14,90', imagemUrl: figado },
    { id: 28, nome: 'Ponta de Picanha', preco: 'R$ 39,90', imagemUrl: pontadepicanha },
    { id: 29, nome: 'Língua Bovina', preco: 'R$ 21,90', imagemUrl: lingua },
    { id: 30, nome: 'Coração Bovino', preco: 'R$ 21,90', imagemUrl: coracao },
    { id: 31, nome: 'Tripa', preco: 'R$ 18,90', imagemUrl: tripa },
    { id: 32, nome: 'Bucho', preco: 'R$ 18,90', imagemUrl: bucho },
    { id: 33, nome: 'Linguiça Mista', preco: 'R$ 18,90', imagemUrl: linguicamista },
    { id: 34, nome: 'Linguiça de Frango', preco: 'R$ 18,90', imagemUrl: linguicadefrango },
  ];

  const handleComprar = (produto) => {
    const telefone = '5583994447532'; // Substitua pelo número de telefone desejado
    const mensagem = `Olá, tenho interesse em comprar ${produto.nome}.`;
    const url = `https://wa.me/${telefone}?text=${encodeURIComponent(mensagem)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="bovino-container">
        <div className='container-titulo'>
            <h1 className="destaque-titulo">DESTAQUES</h1>
            <div className='linha'> 
                <hr className='linha-transparente' />
            </div>
        </div>
        <div className='container-card-pai'>
            {produtos.map(produto => (
                <div key={produto.id} className="produto-card">
                    <img src={produto.imagemUrl} alt={produto.nome} />
                    <h3>{produto.nome} KG</h3>
                    <p>{produto.preco}</p>
                    <button onClick={() => handleComprar(produto)}>Comprar</button>
                </div>
            ))}
        </div>
    </div>
  );
}

export default Bovino;

