import React from 'react';

import cc2l from "../img/cc2l.png";
import cc1500l from "../img/cc1500l.png";
import cc350ml from "../img/cc350ml.png";
import cc250ml from "../img/cc250ml.png";
import fanta2l from "../img/fanta2l.png";
import fantauva2l from "../img/fantauva2l.png";
import fantauva250ml from "../img/fantauva350ml.png";
import fanta350ml from "../img/fanta350ml.png";
import fanta250ml from "../img/fanta250ml.png";
import sprite2l from "../img/sprite2l.png";


import "./estilobovino.css"; // Importando o arquivo CSS

function Bebidas() {
    // Exemplo de dados do produto
    
    const produtos = [
      { id: 1, nome: 'Coca cola 2L - ', preco: 'R$ 11,99', imagemUrl: cc2l  },
      { id: 2, nome: 'Coca cola 1.500L - ', preco: 'R$ 7,99', imagemUrl: cc1500l  },
      { id: 3, nome: 'Coca cola 350ML - ', preco: 'R$ 4,00', imagemUrl: cc350ml  },
      { id: 4, nome: 'Coca cola 250ML - ', preco: 'R$ 3,00', imagemUrl: cc250ml  },
      { id: 5, nome: 'Fanta 2L - ', preco: 'R$ 10,00', imagemUrl: fanta2l  },
      { id: 7, nome: 'Fanta 350ML - ', preco: 'R$ 4,00', imagemUrl: fanta350ml  },
      { id: 8, nome: 'Fanta 250ML - ', preco: 'R$ 3,00', imagemUrl: fanta250ml  },
      { id: 8, nome: 'Fanta Uva 2L - ', preco: 'R$ 10,00', imagemUrl: fantauva2l  },
      { id: 9, nome: 'Fanta Uva 350ML - ', preco: 'R$ 4,00', imagemUrl: fantauva250ml  },
      { id: 10, nome: 'Sprite 2L - ', preco: 'R$ 8,00', imagemUrl: sprite2l  },
      
      
    ];

    const handleComprar = (produto) => {
        const telefone = '5583994447532'; // Substitua pelo número de telefone desejado
        const mensagem = `Olá, tenho interesse em comprar ${produto.nome}.`;
        const url = `https://wa.me/${telefone}?text=${encodeURIComponent(mensagem)}`;
        window.open(url, '_blank');
      };
   
  
    return (
      <div className="bovino-container">
          <div className='container-titulo'>
              <h1 className="destaque-titulo">DESTAQUES</h1>
              <div className='linha'> 
                  <hr className='linha-transparente' />
              </div>
          </div>
          <div className='container-card-pai'>
              {produtos.map(produto => (
                  <div key={produto.id} className="produto-card">
                      <img src={produto.imagemUrl} alt={produto.nome} />
                      <h3>{produto.nome} UND</h3>
                      <p> {produto.preco} </p>
                      <button onClick={handleComprar}>Comprar</button>
                  </div>
              ))}
          </div>
      </div>
    );
  }
  export default Bebidas;