import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./componentes/Header";
import Footer from "./componentes/Footer";
import Bovino from "./paginas/bovino";
import Aves from "./paginas/aves";
import Suino from "./paginas/suino";
import Bebida from "./paginas/bebidas";


function App() {
  return (
    <Router>
      <div>
        <Header />
        
        
        <Routes>
          <Route path="" element={<Bovino />} />
          <Route path="/bovino" element={<Bovino />} />
          <Route path="/aves" element={<Aves />} />
          <Route path="/suino" element={<Suino />} />
          <Route path="/bebidas" element={<Bebida />} />
        
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
