import React from 'react';
import peitodefrango from "../img/peitodefrango.png";
import filedepeito from "../img/filedepeito.png";
import coxaesobre from "../img/coxaesobre.png";
import bisteca from "../img/bistecadefrango.png";
import coxadaasa from "../img/coxadaasa.png";
import frangointeiro from "../img/frangointeiro.png";
import frangoapassarinha from "../img/frangoapassarinha.png";
import coracaodefrango from "../img/coracaodefrango.png";


import "./estilobovino.css"; // Importando o arquivo CSS

function Aves() {
    // Exemplo de dados do produto
    
    const produtos = [
      { id: 1, nome: 'Peito de frango', preco: 'R$ 15,90', imagemUrl: peitodefrango  },
      { id: 2, nome: ' Filé de Peito', preco: 'R$ 21,90', imagemUrl: filedepeito },
      { id: 3, nome: ' Coxa e sobrecoxa', preco: 'R$ 12,90', imagemUrl: coxaesobre },
      { id: 4, nome: ' Bisteca', preco: 'R$ 15,90', imagemUrl: bisteca },
      { id: 5, nome: ' Coxa', preco: 'R$ 12,90', imagemUrl: coxadaasa },
      { id: 6, nome: ' Frango inteiro', preco: 'R$ 14,90', imagemUrl: frangointeiro },
      { id: 7, nome: ' Frango a Passarinha', preco: 'R$ 12,90', imagemUrl: frangoapassarinha },
      { id: 8, nome: ' Coração de frango', preco: 'R$ 36,90', imagemUrl: coracaodefrango },
      
      
    ];

    const handleComprar = (produto) => {
        const telefone = '5583994447532'; // Substitua pelo número de telefone desejado
        const mensagem = `Olá, tenho interesse em comprar ${produto.nome}.`;
        const url = `https://wa.me/${telefone}?text=${encodeURIComponent(mensagem)}`;
        window.open(url, '_blank');
      };
   
  
    return (
      <div className="bovino-container">
          <div className='container-titulo'>
              <h1 className="destaque-titulo">DESTAQUES</h1>
              <div className='linha'> 
                  <hr className='linha-transparente' />
              </div>
          </div>
          <div className='container-card-pai'>
              {produtos.map(produto => (
                  <div key={produto.id} className="produto-card">
                      <img src={produto.imagemUrl} alt={produto.nome} />
                      <h3>{produto.nome} KG</h3>
                      <p> {produto.preco} </p>
                      <button onClick={() => handleComprar(produto)}>Comprar</button>
                  </div>
              ))}
          </div>
      </div>
    );
  }
  
  export default Aves;