import React, { useState } from 'react'

import "./estiloheader.css"; // Arquivo de estilo para estilização específica do Header
import logo from "./logo-lojao.png";
import { FaWhatsapp, FaMotorcycle } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";





function Header() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    }

    return (
        <div className="header-container">
            {/* container de contato */}
            <div className="container-contato">
                <div className="contato">
                    <p> <FaWhatsapp color="green" />
                    83 98171-9385 </p>
                </div>
                <p> <IoMdTime color="white"/>
                Tempo de entrega - 90 a 60 minutos</p>
                <p> <FaMotorcycle color="red" /> Taxa de entrega; R$ 12,00 </p>
                
            </div>
           
            {/* Container com a logo da empresa */}
            <div className="logo-container">
                    <a href="/bovino">
                        <img src={logo} alt="Logo da Empresa" />
                    </a>
            </div>
                            
            {/* container com o horario de funcionamento */}
            <div className="hr-funcionamento">
                <h3>Horário de Funcionamento</h3>
                <p>Segunda a Sábado: 7 ás 18</p>
                <p>Domingo: 7 ás 13</p>
            </div>
            
            {/* container com o horario de funcionamento */}
            <div className="container-menu">
                <button className="menu-toggle" onClick={toggleMenu}>
                    ☰ Menu
                </button>
                <nav className="menu">
                    <ul className={isOpen ? 'active' : ''}>
                    <li><a href="bovino">Bovino</a></li>
                    <li><a href="aves">Aves</a></li>
                    <li><a href="suino">Suíno</a></li>
                    <li><a href="bebidas">Bebidas</a></li>
                    <li><a href="diversos">Diversos</a></li>
                    </ul>
                </nav>
            </div>
    
    </div>
    );
}

export default Header;