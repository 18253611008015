import React from "react";
import { FaWhatsapp, FaMotorcycle, FaCreditCard } from "react-icons/fa";
import { IoMdTime, IoIosPhonePortrait, IoIosTimer  } from "react-icons/io";
import { GiMoneyStack } from "react-icons/gi";
import { MdLocationPin, MdAttachMoney } from "react-icons/md";
import { RiVolumeVibrateFill } from "react-icons/ri";


import "./stylefooter.css"; // Importando o arquivo CSS

function Footer() {
    return(
        
    <div className="container-footer"> 
           
          
       <div className="form-pagamento">
              <h3> <MdAttachMoney color="green" />Formas de Pagamento</h3>
              <ul>
                     <li> <GiMoneyStack color="green" /> Dinheiro</li>
                     <li> <IoIosPhonePortrait color="white" /> Pix</li>
                     <li> <FaCreditCard color="red" /> Cartão de Crédito</li>
                     <li> <FaCreditCard color="blue" /> Cartão de débito</li>
                     <li> <FaCreditCard color="white" /> Cartão pro alimentação</li>
                     <li> <FaCreditCard color="blue" />Cartão mais pão e leite</li>
              </ul>
       </div>

       <div className="Localizacao">
              <h3><MdLocationPin color="white" />
              Localização</h3>
                     <p>Avenida dois de Fevereiro - 1100</p>
                     <p>Varjão</p>
                     <p>Cep 58.070-000</p>
       </div>
          
       <div className="taxa-entrega">
              <h3> <RiVolumeVibrateFill />Taxa de entrega</h3>
                     <p> <FaMotorcycle color="red" /> 
                            Taxa de entrega; R$ 12,00 
                     </p>
          
                     <p> <IoMdTime color="white"/>
                            Tempo de entrega - 90 a 60 minutos
                     </p>
                
       </div>

          <div className="hrdefuncionamento">
              <h3> <IoIosTimer color="white" />Horário de Funcionamento</h3>
              <p>Segunda a Sábado: 7 ás 18</p>
              <p>Domingo: 7 ás 13</p>
          </div>
       
    </div>
       
       );

}

export default Footer;